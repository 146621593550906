'use strict';
import './jquery.js';
import 'bootstrap';
import 'owl.carousel';
import { style as styles } from './mapstyle.js';
import GMaps from 'gmaps';

$(document).ready(() => {
  $(window).on('load', function () {
    const portafolio = $(".portafolio .owl-carousel"),
      next_port = $(".portafolio .arrow_front"),
      prev_port = $(".portafolio .arrow_back"),
      historias = $(".historias .owl-carousel"),
      next_hist = $(".historias .arrow_front"),
      prev_hist = $(".historias .arrow_back"),
      total_hist = $(".historias .total"),
      current_hist = $(".historias .current"),
      wind = $(window),
      navbar = $('.navbar');

    portafolio.owlCarousel({
      loop: true,
      margin: 10,
      responsiveClass: true,
      rewind: false,
      dots: false,
      stagePadding: 100,
      responsive: {
        0: {
          items: 1
        },
        480: {
          items: 2
        }
      }
    });

    portafolio.on('changed.owl.carousel', (e) => {
      let currentItem = e.item.index;
      console.log(currentItem)
    });

    next_port.click(e => {
      e.preventDefault();
      portafolio.trigger('next.owl.carousel');
    });

    prev_port.click(e => {
      e.preventDefault();
      portafolio.trigger('prev.owl.carousel', [300]);
    });

    historias.owlCarousel({
      loop: false,
      margin: 10,
      responsiveClass: true,
      rewind: false,
      dots: false,
      responsive: {
        0: {
          items: 1
        }
      }
    });

    historias.on('changed.owl.carousel', (e) => {
      let currentItem = e.item.index;
      current_hist.html(currentItem + 1);
    });

    next_hist.click(e => {
      e.preventDefault();
      historias.trigger('next.owl.carousel');
    });

    prev_hist.click(e => {
      e.preventDefault();
      historias.trigger('prev.owl.carousel', [300]);
    });


    if (navbar.length > 0) {
      wind.on("scroll load resize", () => {
        const startY = navbar.height() * 2;
        if (wind.scrollTop() > startY) {
          navbar.addClass("scrolled");
        } else {
          navbar.removeClass("scrolled");
        }
      });
    }

    if ($("#map").length) {
      const map = new GMaps({
        div: '#map',
        lat: 19.425535,
        lng: -99.1694809,
        styles
      });

      map.addMarker({
        lat: 19.425535,
        lng: -99.1694809,
      });
    }
  });
});

